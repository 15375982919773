import _ from 'lodash';
import { canUseDOM } from './dom';
import qs from 'qs';

function decoder(str, defaultDecoder, charset, type) {
  if (charset === 'iso-8859-1') {
    const strWithoutPlus = str.replace(/\+/g, ' ');
    return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
  }

  if (/^[+-]?\d+(\.\d+)?$/.test(str)) {
    return parseFloat(str);
  }

  const keywords = {
    true: true,
    false: false,
    null: null,
    undefined: undefined,
  };
  if (type === 'value' && str in keywords) {
    return keywords[str];
  }

  return defaultDecoder(str, defaultDecoder, charset);
}

const stringifyOptions = {
  encodeValuesOnly: true,
  arrayFormat: 'brackets',
  skipNulls: true,
  decoder,
};
export const stringify = obj => {
  return qs.stringify(obj, stringifyOptions);
};

const parseOptions = {
  ignoreQueryPrefix: true,
  decoder,
};

function overrideDecodedTypes(query) {
  //Since the filters are now scoped, we need to dig into the scopes to find the applied filters
  return Object.entries(query).reduce((acc, [scope, scopedParams]) => {
    if (typeof scopedParams === 'object' && scopedParams !== null) {
      const updatedScopedParams = { ...scopedParams };

      // Convert the keys `q`, `search`, or `path` to strings if they exist
      ['q', 'search', 'path'].forEach((key) => {
        if (key in updatedScopedParams) {
          updatedScopedParams[key] = updatedScopedParams[key]?.toString();
        }
      });
      acc[scope] = updatedScopedParams;
    } else {
      acc[scope] = scopedParams;
    }

    return acc;
  }, {});
}

export const parse = str => overrideDecodedTypes(qs.parse(str, parseOptions));

const mergePathParam = (values) => {
  if (!canUseDOM()) { return values }
  if (typeof(PB_WILDCARD_BASE) !== 'undefined' && typeof(PB_WILDCARD) !== 'undefined') {
    const { pathname } = document.location;
    let wildcard = pathname.match(new RegExp(PB_WILDCARD_BASE.replace('/*', '/(?<param>.*)')))?.groups?.param;
    if (wildcard && wildcard.indexOf(',') !== -1) { wildcard = wildcard.split(',') }
    values = {
      ...values,
      ...(wildcard ? { [PB_WILDCARD]: wildcard } : {})
    }
  }
  return values
}

const getParams = () => {
  const queryStr = canUseDOM() ? window?.location?.search : '';

  return mergePathParam(parse(queryStr));
};

const getPathAndQuery = (params) => {
  if (typeof(PB_WILDCARD_BASE) !== 'undefined' && typeof(PB_WILDCARD) !== 'undefined') {
    let { [PB_WILDCARD]: wildcard, ...rest } = params;
    if (Array.isArray(wildcard)) { wildcard = wildcard.join(',') }
    return {
      pathname: '/' + PB_WILDCARD_BASE.replace('/*', wildcard ? `/${wildcard}` : ''),
      queryParams: rest
    }
  }
  return {
    pathname: document.location.pathname,
    queryParams: params
  }
}

const setParams = params => {
  if (!canUseDOM()) { return }
  const { pathname, queryParams } = getPathAndQuery(params);
  const hash = window.location.hash;
  let paramStr = stringify(queryParams);
  // don't add trailing '?' if params are blank
  if (paramStr.length > 0) {
    history.replaceState(null, '', `${pathname}?${paramStr}${hash}`);
  } else {
    history.replaceState(null, '', `${pathname}${hash}`);
  }
};

// install some hooks URL update events. popstate doesn't seem reliable.
const installHistoryHooks = () => {
  let currentParams;
  let previousParams;
  // emit paramsChanged if params are different
  const emitIfParamsChanged = () => {
    previousParams = currentParams;
    currentParams = getParams();
    if (!_.isEqual(currentParams, previousParams)) {
      window.dispatchEvent(new Event('paramsChanged'));
    }
  };

  const { pushState, replaceState } = window.history;
  window.history.pushState = function (...args) {
    pushState.apply(window.history, args);
    emitIfParamsChanged();
  };

  window.history.replaceState = function (...args) {
    replaceState.apply(window.history, args);
    emitIfParamsChanged();
  };
};
// trying to not blow up SSR. not sure if necessary or not.
if (canUseDOM()) {
  installHistoryHooks();
}

// only fired when URL params are changed (by hash comparison)
const subscribeToParamUpdate = callback => {
  canUseDOM() &&
    window?.addEventListener &&
    window.addEventListener('paramsChanged', callback);
};

export { getParams, setParams, subscribeToParamUpdate };
